import { useState, useEffect } from "react"
import PubSub from 'pubsub-js'

import "./VideoListComponent.scss"

import Item from "./Item.js"

export default function VideoComponent ( props ) {
    const [active, setActive] = useState(null)
    const [filters, setFilters] = useState(['All'])
    const [isHidden, setIsHidden] = useState(false)

    var launchedToken = PubSub.subscribe( 'Event_VideoPlayerLaunched', () => setIsHidden( true ) )
    var closedToken = PubSub.subscribe( 'Event_VideoPlayerClosed', () => setIsHidden( false ) )

    useEffect(() => {
        if(active === null) setIsHidden(false)
    }, [active])

    const toggleFilter = (filter) => {
        setFilters(prevFilters => {
            let index = prevFilters.indexOf(filter)
            let indexOfAll = prevFilters.indexOf('All')
            if( filter !== 'All' && indexOfAll > -1 ) prevFilters.splice(indexOfAll, 1)
            if( filter === 'All' ) {
                prevFilters = ['All']
            } else if (index > -1) {
                prevFilters.splice(index, 1)
            } else {
                prevFilters.splice(0, 0, filter)
            }
            return prevFilters
        })
    }

    const isFilterActive = (filter) => {
        if(filters.indexOf('All') > -1 && filter === 'All') return true

        return filters.indexOf(filter) > -1
    }

    const filterVideos = (videoCategories) => {
        if(filters.indexOf('All') > -1) return true

        return videoCategories.length ? filters.indexOf(videoCategories[0]) > -1 : false
    }
    
    return (
        <div className="video_list_component">
            {!isHidden ? ( 
                <div className="video_list_component__filter" style={ active !== null ? { opacity: 0.1 } : null }>
                    <ul className="video_list_component__filter__list">
                        <li className="video_list_component__filter__list__item" style={{ opacity: isFilterActive('All') ? '1' : '0.5' }} onClick={e => toggleFilter('All')}>All</li>
                        {props.categories.map((category, index) => <li key={index} className="video_list_component__filter__list__item" style={{ opacity: isFilterActive(category.name) ? '1' : '0.5' }} onClick={e => toggleFilter(category.name)}>{category.name}</li> )}
                    </ul>
                </div>
            ) : null}
            <ul className="video_list_component__list">
                {props.videos.map( ( video, index ) => {
                    return filterVideos(video.categories) ? (
                        <Item 
                            key={index} 
                            index={index} 
                            active={active} 
                            slug={video.slug} 
                            url={video.url} 
                            title={video.title} 
                            client={video.client} 
                            cover={video.cover} 
                            setActive={setActive}
                        />
                    ) : null
                })}
            </ul>
        </div>
    )
}