import { useEffect, useCallback, useState, useRef } from "react"
import { useSwipeable } from "react-swipeable"
import _ from "lodash"
// import hoverEffect from "hover-effect"
import PubSub from 'pubsub-js'
import { motion, AnimatePresence } from "framer-motion"
import { useLocation } from "react-router-dom"

import VideoComponent from "components/Video/VideoComponent"
// import HoverEffect from "components/HoverEffectSlide/HoverEffectSlide"
// import VideoTransitionComponent from "components/VideoTransition/VideoTransitionComponent"

import "./FeaturedVideoListComponent.scss"

import Item from "./Item.js"

import displacementMap from "assets/img/displacement-map.jpg"

export default function FeaturedVideoListComponent ( props ) {
    const [active, setActive] = useState(0)
    const [offsetTop, setOffsetTop] = useState(0)
    const [transition, setTransition] = useState(null)
    const [isAtStart, setIsAtStart] = useState(true)
    const [isAtEnd, setIsAtEnd] = useState(false)
    const [isVideoPlaying, setIsVideoPlaying] = useState(false)
    const location = useLocation()
    const ref = useRef()
    const backgroundRef = useRef()

    var launchedToken = PubSub.subscribe( 'Event_VideoPlayerLaunched', () => setIsVideoPlaying( true ) )
    var closedToken = PubSub.subscribe( 'Event_VideoPlayerClosed', () => setIsVideoPlaying( false ) )

    const swipeHandler = useSwipeable({
        onSwipedUp: eventData => goToNextVideo(transition, active, isAtEnd),
        onSwipedDown: eventData => goToPreviousVideo(transition, active, isAtStart),
        // ...config,
    })

    useEffect(() => {
        if(active === 0) setIsAtStart(true)
        else setIsAtStart(false)

        if(active === props.videos.length - 1) setIsAtEnd(true)
        else setIsAtEnd(false)

        setOffsetTop( active * ref.current.clientHeight )
    }, [active, props.videos.length])

    // useEffect(() => {
    //     if(props.videos.length) setTransition(new HoverEffect({
    //         parent: backgroundRef.current,
    //         intensity: -0.65,
    //         speedIn: 1.2,
    //         speedOut: 1.2,
    //         // image1: props.videos[0].url,
    //         // image2: props.videos[1].url,
    //         images: props.videos.map(video => video.url),
    //         transitionDelay: 10000,
    //         crossOrigin: 'anonymous',
    //         video: true,
    //         hover: false,
    //         imagesRatio: 9 / 16,
    //         displacementImage: displacementMap
    //     }))
    // }, [props.videos])

    useEffect(() => {
        if(transition) {
            transition.playSlideshow()
            // transition.stopSlideshow()
        }
    }, [transition])

    const handleWindowWheelEvent = useCallback((e) => {
        if((mouseWheelDirection(e) === 'up' && isAtStart) || (mouseWheelDirection(e) === 'down' && isAtEnd)) return null
        
        if(mouseWheelDirection(e) === 'up') {
            goToPreviousVideo(transition, active, isAtStart)
        } else if(mouseWheelDirection(e) === 'down') {
            goToNextVideo(transition, active, isAtEnd)
        } 
    }, [transition, isAtStart, isAtEnd, active, isVideoPlaying])

    const debounceHandleWindowWheelEvent = useCallback(_.debounce(handleWindowWheelEvent, props.hasOwnProperty('throttle') ? props.throttle : 300), [transition, isAtStart, isAtEnd, active, isVideoPlaying])

    const onWindowMouseWheelEvent = useCallback(e => !isVideoPlaying ? debounceHandleWindowWheelEvent(e) : false, [transition, isAtStart, isAtEnd, active, isVideoPlaying])

    useEffect(() => {
        window.addEventListener('wheel', onWindowMouseWheelEvent)

        return () => {
            window.removeEventListener('wheel', onWindowMouseWheelEvent)
        };
    }, [onWindowMouseWheelEvent, transition, isAtStart, isAtEnd, active, isVideoPlaying])

    const mouseWheelDirection = (e) => {
        var delta = null, direction = false

        if(!e) e = window.event // if the event is not provided, we get it from the window object
        if(e.wheelDelta) { // will work in most cases
            delta = e.wheelDelta / 60
        } else if(e.detail) { // fallback for Firefox
            delta = -e.detail / 2
        }
        if(delta !== null) direction = delta > 0 ? 'up' : 'down'

        return direction
    }

    const goToNextVideo = (carousel, activeIndex, end) => {
        if(end) return null
        setActive(activeIndex + 1)
        carousel.next()
    }

    const goToPreviousVideo = (carousel, activeIndex, start) => {
        if(start) return null
        setActive(activeIndex - 1)
        carousel.previous()
    }
    
    return (
        <div {...swipeHandler}>
            <div ref={ref} className="featured_video_list_component">
                <ul className="featured_video_list_component__list" style={{ transform: `translateY(-${offsetTop}px)` }}>
                    {props.videos.map( ( video, index ) => (
                        <Item 
                            key={index} 
                            is_active={active === index} 
                            slug={video.slug} 
                            url={video.url} 
                            title={video.title} 
                            client={video.client} 
                            cover={video.cover}
                        />
                    ))}
                </ul>

                <div className="featured_video_list_component__transition">
                    <AnimatePresence mode="sync">
                        {props.videos.map( ( video, index ) => (active === index) ? (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 1, ease: 'easeInOut' }}
                                key={index}
                            >
                                <VideoComponent 
                                    url={video.url} 
                                    cover={video.cover} 
                                    isLooping={true} 
                                    title={video.title} 
                                    client={video.client} 
                                    slug={video.slug}
                                    parentTitle={'Craig Moore'}
                                    parentUrl={location.pathname}
                                />
                            </motion.div>
                        ) : null )}
                    </AnimatePresence>
                </div>

                {/* <VideoTransitionComponent /> */}

                {/* {props.videos ? <div ref={backgroundRef} className="featured_video_list_component__background"></div> : null} */}

                {/*{props.videos.length ? <HoverEffect 
                    className="featured_video_list_component__background"
                    ref={backgroundRef} 
                    parentProp={backgroundRef} 
                    imagesProp={props.videos.map(video => video.url)} 
                    displacementImageProp={displacementMap} 
                    intensityProp={-0.65}
                    speedInProp={1.2}
                    speedOutProp={1.2}
                    transitionDelayProp={10000}
                    crossOriginProp={'anonymous'}
                    isVideoProp={true}
                    hoverProp={false}
                    imagesRatioProp={9 / 16}
                /> : null}*/}

                <button onClick={goToNextVideo} className="featured_video_list_component__scroll_btn" style={ isAtEnd || isVideoPlaying ? { opacity: 0 } : null }>
                    <span className="featured_video_list_component__scroll_btn__text">Scroll</span>
                    <span className="featured_video_list_component__scroll_btn__icon">&#8595;</span>
                </button>
            </div>
        </div>
    )
}