import { useNavigate } from "react-router-dom";
import PubSub from 'pubsub-js'

import "./Controls.scss"

export default function Controls ( props ) {
    const navigate = useNavigate();

    const returnToWork = () => {
        PubSub.publish('Event_VideoPlayerClosed')
        navigate('/work')
    }

    return (
        <div className="video__controls" style={{ transform: props.isHidden ? 'translateY(100%)' : null }}>
            <div className="video__controls__playback">
                <div className="video__controls__playback__buttons">
                    <button className="video__controls__button video__controls__playback__buttons__button" onClick={props.toggleIsPlaying}>{props.isPlaying ? 'Pause' : 'Play'}</button>
                    <button className="video__controls__button video__controls__playback__buttons__button" onClick={props.toggleIsMuted}>{props.isMuted ? 'Unmute' : 'Mute'}</button>
                </div>
                <div className="video__controls__playback__info">
                    <span className="video__controls__playback__info__duration" >{props.currentTime} / {props.duration}</span>
                    <div className="video__controls__playback__info__position" onClick={props.handleSeek}>
                        <div className="video__controls__playback__info__position__bar">
                            <div className="video__controls__playback__info__position__bar__progress" style={{ width: `${props.playbackPercentage}%` }}></div>
                        </div>
                    </div>
                </div>
            </div>
            <button className="video__controls__button video__controls__expand" onClick={props.toggleFullscreen}>Expand</button>
            {props.isDirectLink ? (
                <button onClick={returnToWork} className="video__controls__button video__controls__close" >All Work</button>
            ) : <button onClick={props.closePlayer} className="video__controls__button video__controls__close">Close</button>}
        </div>
    );
}