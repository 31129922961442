import { useState, useEffect } from "react"

import "./ContactView.scss"

import LoadingComponent from 'components/Loading/LoadingComponent'

export default function ContactView(props) {
    const endpoint = 'contact'
    const [data, setData] = useState(null)

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/page/${endpoint}`, {
            method: 'GET',
            mode: 'cors'
        })
        .then(response => response.json())
        .then(data => setData(data))
    }, [])

    if(!data) return <LoadingComponent />

    return (
        <div className="contact_view">
            <h1 className="contact_view__title">{data.title}</h1>
            <div className="contact_view__contact_details">
                <ul className="contact_view__contact_details__list">
                    {data.metadata.details.map((item, index) => (
                        <li key={index} className="contact_view__contact_details__list__item">
                            <span className="contact_view__contact_details__list__item__name">{item.name}</span>
                            <span className="contact_view__contact_details__list__item__value">
                                <a href={item.type+':'+item.value}>
                                    {item.value}
                                </a>
                            </span>
                        </li>
                    ))}
                </ul>
                <ul className="contact_view__contact_details__social">
                    {data.metadata.social_media_links.map((item, index) => (
                        <li key={index} className="contact_view__contact_details__social__item">
                            <a href={item.link} target="_blank" rel="noopener noreferrer">
                                {item.name}
                                {/* <svg className="contact_view__contact_details__social__item__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" /> */}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}