import { useEffect, useState } from "react"

import "./MouseComponent.scss"

export default function MouseComponent ( props ) {
    const [coordinates, setCoordinates] = useState({ left: 0, top: 0 })

    const lerp = ( start, end, amt ) => {
        return (1-amt) * start + amt * end
    }

    useEffect(() => {
        setCoordinates(prevCoordinates => ({ 
            left: lerp( props.x-props.width/2, prevCoordinates.left, 0.1 ), 
            top: lerp( props.y-props.height/2, prevCoordinates.top, 0.1 ) 
        }))
    }, [props.x, props.y, props.width, props.height])
    
    return (
        <div className="mouse_component" style={{ transform: `translate3d(${coordinates.left}px, ${coordinates.top}px, 0)`, display: props.isHidden ? 'none' : 'inline-block' }}></div>
    )
}