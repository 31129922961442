import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { motion } from "framer-motion"

import "./IntroView.scss"

import logo from "assets/svg/logo-wavy.svg"
import intro from "assets/videos/intro.mp4"

const variants = {
    initial: { opacity: 0 },
    animate: { 
        opacity: [0, 1, 0, 1, 0, 1, 1, 0] 
    },
    exit: { opacity: 0 },
    transition: { 
        duration: 3,
        times: [0, 0.1, 0.2, 0.6, 0.8, 1, 1.8, 1.9] 
    },
}

export default function IntroView( props ) {
    const navigate = useNavigate()
    const [isVisible, setIsVisible] = useState(false)
    const [isLogoVisible, setIsLogoVisible] = useState(false)

    const beginExit = () => {
        setIsLogoVisible(true)
        setTimeout(exit, 3000)
    }

    const exit = () => {
        setTimeout(navigate("/featured"), 3000)
    }

    return (
        <div onClick={exit} className="intro_view" style={{ opacity: isVisible ? 1 : 0 }}>
            <video onPlay={e => setIsVisible(true)} onEnded={beginExit} className="intro_view__video" muted autoPlay playsInline loop={false}>
                <source src={intro} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            {isLogoVisible ? (
                <motion.img 
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    transition="transition"
                    variants={variants}
                    className="intro_view__logo" 
                    src={logo} 
                    alt="Craig Moore" 
                /> ) : null}
        </div>
    )
}