import { useState, useEffect } from "react"

import "./FeaturedView.scss"

import FeaturedVideoListComponent from "components/FeaturedVideoList/FeaturedVideoListComponent"

// import { videos } from "data/VideoData"

import LoadingComponent from 'components/Loading/LoadingComponent'

export default function FeaturedView( props ) {
    const endpoint = 'works'
    const [videos, setVideos] = useState(null)

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/${endpoint}&is_featured=1`, {
            method: 'GET',
            mode: 'cors'
        })
        .then(response => response.json())
        .then(data => setVideos(data))
    }, [])

    if(!videos) return <LoadingComponent />

    return (
        <div className="featured_view">
            <FeaturedVideoListComponent videos={videos} />
        </div>
    )
}