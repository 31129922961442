import { useState, useEffect } from "react"
import { Route, Routes, useLocation } from "react-router-dom"

import './App.scss'

import NavigationComponent from "components/Navigation/NavigationComponent"

import IntroView from "views/Intro/IntroView"
import FeaturedView from "views/Featured/FeaturedView"
import ArchiveView from "views/Archive/ArchiveView"
import WorkItemView from "views/WorkItem/WorkItemView"
import AboutView from "views/About/AboutView"
import ContactView from "views/Contact/ContactView"
import MouseComponent from "components/Mouse/MouseComponent"

const navigationLinks = [
  {
    link: '/work',
    name: 'Work',
    displayBottom: false
  },
  {
    link: '/archive',
    name: 'Archive',
    displayBottom: false
  },
  {
    link: '/about',
    name: 'About',
    displayBottom: true
  },
  {
    link: '/contact',
    name: 'Contact',
    displayBottom: true
  }
]

function App( ) {
  const location = useLocation()
  const [coordinates, setCoordinates] = useState({ x: window.innerWidth, y: window.innerHeight })
  const [timer, setTimer] = useState(0)
  const [isMouseHidden, setIsMouseHidden] = useState(false)

  const handleMouseMove = ( e ) => {
    setTimer(0)
    setCoordinates({ x: e.pageX, y: e.pageY })
  }

  useEffect(() => {
    const loop = setInterval(() => {
        setTimer(prevTimer => prevTimer + 1)
    }, 1000)

    return () => {
        clearInterval(loop);
    }
  }, [])

  useEffect(() => {
    if(timer > 2) setIsMouseHidden(true)
    else setIsMouseHidden(false)
  }, [timer])

  return (
    <div onMouseMove={e => handleMouseMove(e)} className={isMouseHidden ? 'application_container mouse_hidden' : 'application_container'}>
      {window.innerWidth >= 960 ? ( <MouseComponent isHidden={isMouseHidden} x={coordinates.x} y={coordinates.y} width={60} height={60} /> ) : null}
      {location.pathname !== '/' ? ( <NavigationComponent links={navigationLinks} /> ) : null}
      <Routes key={location.pathname} location={location}>
        <Route path="/" element={<IntroView />} />
        <Route path="/featured" element={<FeaturedView />} />
        <Route path="/work" element={<ArchiveView />} />
        <Route path="/archive" element={<ArchiveView />} />
        <Route path="/work/:slug" element={<WorkItemView />} />
        <Route path="/about" element={<AboutView />} />
        <Route path="/contact" element={<ContactView />} />
      </Routes>
    </div>
  )
}

export default App