import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"

import VideoComponent from "components/Video/VideoComponent"
import LoadingComponent from 'components/Loading/LoadingComponent'

import "./WorkItemView.scss"

export default function WorkItemView(props) {
    const endpoint = 'works'
    const { slug } = useParams()
    const [video, setVideo] = useState(null)

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/${endpoint}/${slug}`, {
            method: 'GET',
            mode: 'cors'
        })
        .then(response => response.json())
        .then(data => setVideo(data))
    }, [])

    if(!video) return <LoadingComponent />

    return (
        <VideoComponent 
            url={video.url} 
            cover={video.cover} 
            isFullscreen={true} 
            isLooping={false}
            title={video.title} 
            client={video.client} 
            slug={video.slug}
            isDirectLink={true}
        /> 
    )
}