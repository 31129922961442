import { useState, useEffect } from "react"
// import { motion, AnimatePresence } from "framer-motion"
import { ParallaxProvider, Parallax } from 'react-scroll-parallax'
import "./AboutView.scss"

import LoadingComponent from 'components/Loading/LoadingComponent'

export default function AboutView(props) {
    const endpoint = 'about'
    const [data, setData] = useState(null)
    const [isDisabled, setIsDisabled] = useState(false)

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/page/${endpoint}`, {
            method: 'GET',
            mode: 'cors'
        })
        .then(response => response.json())
        .then(data => setData(data))
    }, [])

    useEffect(() => {
        const resizeListener = () => {
          if(window.innerWidth < 960) {
            setIsDisabled(true)
          }
        }
        resizeListener()
        window.addEventListener('resize', resizeListener)
    
        return () => {
          window.removeEventListener('resize', resizeListener)
        }
    }, [])

    if(!data) return <LoadingComponent />

    return (
        <ParallaxProvider>
            <div className="about_view">
                <h1 className="about_view__title">{data.title}</h1>

                <div className="about_view__intro">
                    <Parallax 
                        speed={-20}
                        className="about_view__intro__content" 
                        disabled={isDisabled}
                        dangerouslySetInnerHTML={{ __html: data.metadata.introduction }}
                    ></Parallax>
                    <Parallax 
                        speed={-5}
                        disabled={isDisabled}
                        className="about_view__intro__image" 
                    >
                        <img
                            // initial={{ opacity: 0, y: '80vh' }}
                            // whileInView={{ opacity: 1, y: 0 }} 
                            // transition={{ duration: 1, ease: 'easeOut' }}
                            src={data.metadata.image.url} 
                            alt={data.metadata.image.alt} 
                        />
                    </Parallax>
                </div>

                <div
                    // initial={{ opacity: 0, y: '30vh' }}
                    // whileInView={{ opacity: 1, y: 0 }} 
                    // transition={{ duration: 1, delay: 1, ease: 'easeOut' }} 
                    className="about_view__about"
                >
                    <Parallax 
                        speed={-5}
                        disabled={isDisabled}
                        className="about_view__about__image" 
                    >
                        <img
                            src={data.metadata.work_image.url} 
                            alt={data.metadata.work_image.alt} 
                        />
                    </Parallax>
                    <Parallax 
                        speed={10}
                        disabled={isDisabled}
                        className="about_view__about__content" 
                        dangerouslySetInnerHTML={{ __html: data.metadata.content }}
                    ></Parallax>
                </div>

                {data.metadata.awards_and_press ? (
                    <div className="about_view__awards_and_press">
                        <h2 className="about_view__awards_and_press__title">Awards &amp; Press</h2>

                        <ul className="about_view__awards_and_press__list">
                            {data.metadata.awards_and_press.map((item, index) => (
                                <li key={index} className="about_view__awards_and_press__list__item">
                                    <h3>{item.name}</h3>
                                    <span dangerouslySetInnerHTML={{ __html: item.details }}></span>
                                    {item.image ? (
                                        <div className="about_view__awards_and_press__list__item__image">
                                            <img
                                                src={item.image.url} 
                                                alt={item.image.alt} 
                                            />
                                        </div>
                                    ) : null}
                                </li>
                            ))}
                        </ul>
                    </div>
                ) : null}
            </div>
        </ParallaxProvider>
    )
}