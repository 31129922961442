import { useState, useEffect } from "react"
import { motion, AnimatePresence } from "framer-motion"
import PubSub from 'pubsub-js'
import { useLocation } from "react-router-dom"

import VideoComponent from "components/Video/VideoComponent"

export default function Item ( props ) {
    const [isVisible, setIsVisible] = useState(true)
    const [isHidden, setIsHidden] = useState(false)
    const [isFullscreen, setIsFullscreen] = useState(false)
    const location = useLocation()

    var launchedToken = PubSub.subscribe( 'Event_VideoPlayerLaunched', () => setIsHidden( true ) )
    var closedToken = PubSub.subscribe( 'Event_VideoPlayerClosed', () => setIsHidden( false ) )

    useEffect(() => {
        if(props.active === props.index || props.active === null) setIsVisible(true)
        else setIsVisible(false)
    }, [props.active, props.index])

    useEffect(() => {
        if(props.active !== props.index) {
            setIsHidden(false)
            setIsFullscreen(false)
        }
    }, [props.active])

    const launchVideoPlayer = ( e ) => {
        setIsFullscreen(true)
    }
    
    return (
        <>
            <li                             
                className="video_list_component__list__item" 
                style={{ opacity: isVisible ? 1 : 0.1 }}
            >
                {!isHidden ? (
                    <div 
                        className="video_list_component__list__item__info"
                        onClick={launchVideoPlayer}
                        onMouseEnter={e => props.setActive(props.index)} 
                        onMouseLeave={e => props.setActive(null)} 
                    > 
                        <h2 className="video_list_component__list__item__info__title">
                            {props.client}
                        </h2>
                        <span className="video_list_component__list__item__info__client">
                            {props.title}
                        </span>
                    </div>
                ) : null}
            </li>
            <AnimatePresence>
                {props.active === props.index ? (
                    <motion.div 
                        className="video_list_component__list__item__video"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 1 }}
                    >
                        <VideoComponent 
                            url={props.url} 
                            cover={props.cover} 
                            isLooping={true} 
                            isFullscreen={isFullscreen} 
                            title={props.title} 
                            client={props.client} 
                            slug={props.slug}
                            parentTitle={'Craig Moore'}
                            parentUrl={location.pathname}
                        />
                    </motion.div>
                ) : null}
            </AnimatePresence>
        </>
    )
}