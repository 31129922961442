import { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"

import "./ArchiveView.scss"

import VideoListComponent from "components/VideoList/VideoListComponent"
import LoadingComponent from 'components/Loading/LoadingComponent'

// import { videos } from "data/VideoData"

export default function ArchiveView(props) {
    const location = useLocation()
    const endpoint = 'works'
    const [videos, setVideos] = useState(null)
    const [categories, setCategories] = useState(null)
    const isFeatured = location.pathname === '/archive' ? 0 : 1
    const isWork = location.pathname === '/archive' ? 0 : 1
    const title = location.pathname === '/archive' ? 'Archive' : 'Work'

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/${endpoint}&is_featured=${isFeatured}&is_work=${isWork}`, {
            method: 'GET',
            mode: 'cors'
        })
        .then(response => response.json())
        .then(data => setVideos(data))
    }, [])

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/${endpoint}/categories`, {
            method: 'GET',
            mode: 'cors'
        })
        .then(response => response.json())
        .then(data => setCategories(data))
    }, [])

    if(!videos || !categories) return <LoadingComponent />

    return (
        <div className="archive_view">
            <h1 className="archive_view__title">{title}</h1>
            <VideoListComponent videos={videos} categories={categories} />
        </div>
    )
}