import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import PubSub from 'pubsub-js'

import "./NavigationComponent.scss"

import logo from "assets/svg/logo.svg"

export default function NavigationComponent ( props ) {
    const [isVisible, setIsVisible] = useState(false)

    var launchedToken = PubSub.subscribe( 'Event_VideoPlayerLaunched', () => setIsVisible( false ) )
    var closedToken = PubSub.subscribe( 'Event_VideoPlayerClosed', () => setIsVisible( true ) )

    useEffect( () => {
        setTimeout(setIsVisible(true), 800)
    }, [])

    const showNavigationItem = (displayBottom) => {
        if ( isVisible ) return { opacity: '1', transform: 'translateY(0)' }

        if( !isVisible ) {
            if( !displayBottom ) return { opacity: '0', transform: 'translateY(-100%)' }
            else return { opacity: '0', transform: 'translateY(100%)' }
        }
    }

    return (
        <div className="navigation_component">
            <Link to={"/featured"} className="navigation_component__logo" style={ isVisible ? { opacity: 1, transform: 'translate(-50%, 0)'} : null}>
                <img className="navigation_component__logo__image" src={logo} alt="Craig Moore" />    
            </Link>
            <ul className="navigation_component__list">
                {props.links.map( ( link, index ) => {
                    return (
                        <li key={index} className="navigation_component__list__item" style={showNavigationItem(link.displayBottom)}>
                            <Link to={link.link} className="navigation_component__list__item__link">{link.name}</Link>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}