import { useState } from "react"
import PubSub from 'pubsub-js'
import { useLocation } from "react-router-dom"

import VideoComponent from "components/Video/VideoComponent"

export default function Item ( props ) {
    const [isFullscreen, setIsFullscreen] = useState(false)
    const location = useLocation()

    var closedToken = PubSub.subscribe( 'Event_VideoPlayerClosed', () => {
        setIsFullscreen(false) 
    })

    const launchVideoPlayer = ( e ) => {
        setIsFullscreen(true)
    }
    
    return (
        <li onClick={launchVideoPlayer} className="featured_video_list_component__list__item">
            {!isFullscreen && props.is_active ? (
                <div className="featured_video_list_component__list__item__info"> 
                    <h2 className="featured_video_list_component__list__item__info__client">
                        {props.client}
                    </h2>
                    <span className="featured_video_list_component__list__item__info__title">
                        {props.title}
                    </span>
                </div>
            ) : null }
            {isFullscreen && props.is_active ? (
                <VideoComponent 
                    url={props.url} 
                    cover={props.cover} 
                    isFullscreen={isFullscreen} 
                    title={props.title} 
                    client={props.client} 
                    slug={props.slug}
                    parentTitle={'Craig Moore'}
                    parentUrl={location.pathname}
                /> 
            ) : null}
        </li>
    )
}