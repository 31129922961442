import { useRef, useEffect, useState } from "react"
import PubSub from 'pubsub-js'
import Controls from "./Controls"

import "./VideoComponent.scss"

export default function VideoComponent ( props ) {
    const ref = useRef();

    const [loopStart, setLoopStart] = useState(15)
    const [loopEnd, setLoopEnd] = useState(21)
    const [isPlaying, setIsPlaying] = useState(true)
    const [isMuted, setIsMuted] = useState(true)

    const [currentTime, setCurrentTime] = useState('00:00')
    const [duration, setDuration] = useState('00:00')
    const [playbackPercentage, setPlaybackPercentage] = useState(0)
    const [isControlsHidden, setIsControlsHidden] = useState(false)

    const [timer, setTimer] = useState(0)

    useEffect(() => {
        if(props.isFullscreen) {
            document.title = `${props.client} - ${props.title} | Craig Moore`
            window.history.replaceState(null, `${props.client} - ${props.title} | Craig Moore`, `/work/${props.slug}`)
            setIsMuted( props.isDirectLink ? true : false )
            ref.current.currentTime = 0
            PubSub.publish('Event_VideoPlayerLaunched')
        }
    }, [props.isFullscreen])

    const handleMouseMove = ( e ) => {
        setTimer(0)
    }

    useEffect(() => {
        const loop = setInterval(() => {
            setTimer(prevTimer => prevTimer + 1)
        }, 1000)
    
        return () => {
            clearInterval(loop);
        }
    }, [])

    useEffect(() => {
        if(timer > 2) setIsControlsHidden(true)
        else setIsControlsHidden(false)
    }, [timer])

    useEffect(() => {
        if(ref.current.currentTime < loopStart && props.isLooping && !props.isFullscreen) ref.current.currentTime = loopStart
        const loop = setInterval(() => {
            if (ref.current.currentTime >= loopEnd && props.isLooping && !props.isFullscreen) {
                ref.current.currentTime = loopStart
            }
        }, 1000)

        return () => {
            clearInterval(loop);
        }
    }, [ref, loopStart, loopEnd, props.isLooping, props.isFullscreen])

    useEffect(() => {
        if(isPlaying) ref.current.play()
        else ref.current.pause()
    }, [ref, isPlaying])

    const handleTimeUpdate = ( e ) => {
        setCurrentTime(timeFormat(e.target.currentTime))
        setPlaybackPercentage((e.target.currentTime / e.target.duration) * 100)
    }

    const timeFormat = ( timeInSeconds ) => {
        const minutes = Math.floor(timeInSeconds / 60)
        const seconds = Math.floor(timeInSeconds - minutes * 60)
        return str_pad_left(minutes, '0', 2) + ':' + str_pad_left(seconds, '0', 2)
    }

    const str_pad_left = ( string, pad, length ) => {
        return (new Array(length+1).join(pad) + string).slice(-length)
    }

    const handleDurationChange = ( e ) => {
        setDuration(timeFormat(e.target.duration))
        setPlaybackPercentage((e.target.currentTime / e.target.duration) * 100)
    }

    const closePlayer = ( e ) => {
        if(props.parentTitle && props.parentUrl) {
            document.title = props.parentTitle
            window.history.replaceState(null, `${props.parentTitle}`, `${props.parentUrl}`)
        }
        setIsMuted(true)
        PubSub.publish('Event_VideoPlayerClosed')
    }

    const toggleIsPlaying = ( e ) => {
        setIsPlaying(prevIsPlaying => !prevIsPlaying )
    }

    const toggleIsMuted = ( e ) => {
        setIsMuted(prevIsMuted => !prevIsMuted)
    }

    const toggleFullscreen = ( e ) => {
        if (ref.current.requestFullscreen) ref.current.requestFullscreen()
        else if (ref.current.webkitRequestFullscreen) ref.current.webkitRequestFullscreen()
        else if (ref.current.msRequestFullscreen) ref.current.msRequestFullscreen()
    }

    const handleSeek = ( e ) => {
        ref.current.currentTime = Math.floor((e.clientX - e.target.offsetLeft) / e.target.offsetWidth * 100) / 100 * ref.current.duration
    }
    
    return (
        <div
            className={ props.isFullscreen ? "video v_is_playing" : "video" }
            onMouseMove={e => handleMouseMove(e)}
        >
            {props.isDirectLink ? (
                <div className="video__heading" style={{ transform: isControlsHidden ? 'translateY(-100%)' : null }}>
                    <h1 className="video__heading__title"><strong>{props.client}</strong> {props.title}</h1>
                </div>
            ) : null}
            <video 
                ref={ref} 
                onClick={toggleIsPlaying}
                className="video__video" 
                muted={isMuted}
                playsInline
                poster={props.cover}
                onTimeUpdate={handleTimeUpdate}
                onDurationChange={handleDurationChange}
            >
                <source src={props.url} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            {props.isFullscreen ? (
                <Controls
                    isHidden={isControlsHidden} 
                    isPlaying={isPlaying}
                    isMuted={isMuted}
                    currentTime={currentTime}
                    duration={duration}
                    playbackPercentage={playbackPercentage}
                    toggleIsPlaying={toggleIsPlaying} 
                    toggleIsMuted={toggleIsMuted}
                    toggleFullscreen={toggleFullscreen}
                    closePlayer={closePlayer}
                    handleSeek={handleSeek}
                    isDirectLink={props.isDirectLink}
                />
            ) : null}
        </div>
    )
}